/*
-----------------------------
Fonts
-----------------------------
*/
$nregular: "Neue Regular", Helvetica, Arial, sans-serif;
$nbold: "Neue Bold", Helvetica, Arial, sans-serif;
$nlight: "Neue Light", Helvetica, Arial, sans-serif;
$regular: "DM Sans", Helvetica, Arial, sans-serif;
$bold: "DM Bold", Helvetica, Arial, sans-serif;

/*
-----------------------------
Colores
-----------------------------
*/
$fontcolor: #3b414d;
$naranja: #ff4438;
$azul: #252a36;
$azul2: #83bcff;
$celeste: #f3f8fe;
$gris: #e5e5e5;

/*
-----------------------------
Media queries
-----------------------------
*/
$break-xl: "(max-width: 1399.98px)";
$break-lg: "(max-width: 1199.98px)";
$break-md: "(max-width: 991.98px)";
$break-sm: "(max-width: 767.98px)";
$break-xs: "(max-width: 575.98px)";
$break-480: "(max-width: 480px)";
$break-320: "(max-width: 320px)";
