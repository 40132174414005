@import "variables";

.main-banner {
	background-color: $naranja;
	position: relative;
	overflow: hidden;
	padding: 180px 0;
	.caption {
		opacity: 0;
		position: relative;
		z-index: 10;
		.inner {
			color: #fff;
			small {
				display: block;
				font-family: $bold;
				font-size: 14px;
				letter-spacing: 5px;
				text-transform: uppercase;
				margin-bottom: 15px;
			}
			h1 {
				font-family: $nregular;
				font-size: 64px;
				letter-spacing: 0.018em;
				color: #ffffff;
				line-height: 1.18em;
				color: #ffffff;
			}
			p {
				line-height: 185%;
				margin-bottom: 50px;
			}
		}
	}
	.poster {
		opacity: 0;
		img {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	@media #{$break-lg} {
		padding: 180px 0 120px;
	}

	@media #{$break-md} {
		.caption {
			.inner {
				h1 {
					font-size: 46px;
				}
			}
		}
	}

	@media #{$break-sm} {
		padding-bottom: 0;
		.poster {
			margin: -200px 0 0 0;
			position: relative;
			&::before {
				content: "";
				display: block;
				width: 100%;
				height: 40%;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 5;
				background: linear-gradient(
					180deg,
					$naranja,
					transparentize($naranja, 1)
				);
			}
			img {
				position: relative;
				width: 100%;
				height: auto;
				object-position: right center;
				aspect-ratio: 1;
			}
		}
	}

	@media #{$break-xs} {
		.caption {
			.inner {
				h1 {
					font-size: 36px;
				}
			}
		}
	}

	@media #{$break-480} {
		.caption {
			.inner {
				h1 {
					font-size: 28px;
					line-height: 1.25em;
				}
			}
		}
	}
}

.cta-intro {
	position: relative;
	width: 100%;
	max-width: 1140px;
	margin: auto;
	.block {
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);
		background-color: $azul;
		color: #fff;
		.inner {
			padding: 50px;
			h2 {
				font-family: $nregular;
				font-size: 30px;
				text-align: center;
				line-height: 1.6em;
				letter-spacing: 1px;
				color: #fff;
				margin: 0;
				padding: 0;
				strong {
					font-family: $nbold;
				}
			}
		}
	}
	@media #{$break-lg} {
		.block {
			position: relative;
			top: 0;
			left: 0;
			transform: translateX(0) translateY(0);
			.inner {
				h2 {
					font-size: 26px;
					line-height: 1.35em;
				}
			}
		}
	}
	@media #{$break-md} {
		.block {
			.inner {
				padding: 50px 20px;
				h2 {
					font-size: 22px;
				}
			}
		}
	}
}

.que-es {
	background-color: $celeste;
	padding: 150px 0 100px;

	@media #{$break-md} {
		padding: 100px 0;
	}
}

#solucion {
	svg {
		width: 100%;
		height: auto;
	}
}

.nuestras-soluciones {
	padding: 100px 0;
	position: relative;
	overflow: hidden;
	.pic {
		position: relative;
		img {
			position: relative;
			z-index: 10;
		}
		.dots {
			position: absolute;
			top: 50%;
			left: -100px;
			transform: translateY(-50%);
		}
	}
	@media #{$break-sm} {
		.pic {
			.dots {
				top: 50%;
				left: 50%;
				transform: translateY(0) translateX(-50%);
			}
		}
	}
}

.directorio {
	background-color: $celeste;
	padding: 100px 0;
	position: relative;
	overflow: hidden;
	.pic {
		position: relative;
		.fig {
			position: relative;
			z-index: 10;
		}
		.dots {
			position: absolute;
			top: 0%;
			left: 50%;
		}
	}
	@media #{$break-sm} {
		.pic {
			.fig {
				width: 150%;
				height: auto;
				margin-top: -100px;
			}
			.dots {
				top: 80%;
				left: 50%;
				transform: translateY(0) translateX(-50%);
			}
		}
	}
}

.oportunidades {
	padding: 100px 0;
	position: relative;
	overflow: hidden;
	.pic {
		position: relative;
		.fig {
			position: relative;
			z-index: 10;
		}
		.dots {
			position: absolute;
			top: 0%;
			right: 50%;
		}
	}
	@media #{$break-sm} {
		.pic {
			.dots {
				top: 80%;
				right: 50%;
				transform: translateY(0) translateX(50%);
			}
		}
	}
}

.intercambio {
	background-color: $celeste;
	padding: 100px 0 250px;
	position: relative;
	overflow: hidden;
	.pic {
		position: relative;
		img {
			position: relative;
			z-index: 10;
		}
		.dots {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateY(-50%);
		}
	}
	@media #{$break-md} {
		.pic {
			.dots {
				top: 50%;
				left: 50%;
				transform: translateY(0) translateX(-50%);
			}
		}
	}
}

.infomedia {
	padding: 100px 0;
	padding-bottom: 250px;
	.pic {
		position: relative;
		.fig {
			position: relative;
			z-index: 10;
		}
		.dots {
			position: absolute;
			top: 0%;
			right: -100px;
		}
	}
}

.cta-testimonios {
	position: relative;
	width: 100%;
	max-width: 1140px;
	margin: auto;
	z-index: 20;
	.block {
		position: absolute;
		width: calc(100% - 30px);
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		background: #ffffff;
		border: 1px solid #add2ff;
		box-shadow: 4px 10px 20px rgba(160, 164, 167, 0.27);
		border-radius: 10px 10px 0px 0px;

		.inner {
			padding: 50px;
			small {
				display: block;
				font-family: $bold;
				font-size: 14px;
				letter-spacing: 5px;
				color: $naranja;
				margin-bottom: 20px;
				text-transform: uppercase;
			}
			.lead {
				font-family: $nregular;
				font-size: 30px;
				letter-spacing: 1px;
				line-height: 1.45em;
				span {
					color: $naranja;
					font-size: 50px;
				}
			}
		}
	}
	@media #{$break-md} {
		.block {
			.inner {
				.lead {
					font-size: 24px;
				}
			}
		}
	}
	@media #{$break-sm} {
		.block {
			.inner {
				padding: 50px 15px;
				.lead {
					font-size: 18px;
					span {
						font-size: 22px;
					}
				}
			}
		}
	}
}

.proveedor-medios {
	background-color: $azul;
	position: relative;
	overflow: hidden;
	padding: 250px 0 150px;
	.contw {
		position: relative;
		z-index: 10;
	}
	.poster {
		position: absolute;
		top: 50px;
		right: 0;
		width: 65%;
		height: 100%;
		&::before,
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 5;
		}
		&::before {
			width: 100%;
			height: 80%;
			background: linear-gradient(0deg, transparentize($azul, 1), $azul);
		}
		&::after {
			width: 60%;
			height: 100%;
			background: linear-gradient(90deg, $azul, transparentize($azul, 1));
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center top;
		}
	}
	@media #{$break-sm} {
		padding-bottom: 100px;
		.poster {
			width: 100%;
			&::before {
				height: 100%;
			}
			&::after {
				width: 100%;
			}
			img {
				object-position: right center;
			}
		}
	}
}
