/*
-----------------------------
Fonts
-----------------------------
*/
/*
-----------------------------
Colores
-----------------------------
*/
/*
-----------------------------
Media queries
-----------------------------
*/
.main-banner {
  background-color: #ff4438;
  position: relative;
  overflow: hidden;
  padding: 180px 0;
}
.main-banner .caption {
  opacity: 0;
  position: relative;
  z-index: 10;
}
.main-banner .caption .inner {
  color: #fff;
}
.main-banner .caption .inner small {
  display: block;
  font-family: "DM Bold", Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 5px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.main-banner .caption .inner h1 {
  font-family: "Neue Regular", Helvetica, Arial, sans-serif;
  font-size: 64px;
  letter-spacing: 0.018em;
  color: #ffffff;
  line-height: 1.18em;
  color: #ffffff;
}
.main-banner .caption .inner p {
  line-height: 185%;
  margin-bottom: 50px;
}
.main-banner .poster {
  opacity: 0;
}
.main-banner .poster img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1199.98px) {
  .main-banner {
    padding: 180px 0 120px;
  }
}
@media (max-width: 991.98px) {
  .main-banner .caption .inner h1 {
    font-size: 46px;
  }
}
@media (max-width: 767.98px) {
  .main-banner {
    padding-bottom: 0;
  }
  .main-banner .poster {
    margin: -200px 0 0 0;
    position: relative;
  }
  .main-banner .poster::before {
    content: "";
    display: block;
    width: 100%;
    height: 40%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
    background: linear-gradient(180deg, #ff4438, rgba(255, 68, 56, 0));
  }
  .main-banner .poster img {
    position: relative;
    width: 100%;
    height: auto;
    object-position: right center;
    aspect-ratio: 1;
  }
}
@media (max-width: 575.98px) {
  .main-banner .caption .inner h1 {
    font-size: 36px;
  }
}
@media (max-width: 480px) {
  .main-banner .caption .inner h1 {
    font-size: 28px;
    line-height: 1.25em;
  }
}

.cta-intro {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: auto;
}
.cta-intro .block {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #252a36;
  color: #fff;
}
.cta-intro .block .inner {
  padding: 50px;
}
.cta-intro .block .inner h2 {
  font-family: "Neue Regular", Helvetica, Arial, sans-serif;
  font-size: 30px;
  text-align: center;
  line-height: 1.6em;
  letter-spacing: 1px;
  color: #fff;
  margin: 0;
  padding: 0;
}
.cta-intro .block .inner h2 strong {
  font-family: "Neue Bold", Helvetica, Arial, sans-serif;
}
@media (max-width: 1199.98px) {
  .cta-intro .block {
    position: relative;
    top: 0;
    left: 0;
    transform: translateX(0) translateY(0);
  }
  .cta-intro .block .inner h2 {
    font-size: 26px;
    line-height: 1.35em;
  }
}
@media (max-width: 991.98px) {
  .cta-intro .block .inner {
    padding: 50px 20px;
  }
  .cta-intro .block .inner h2 {
    font-size: 22px;
  }
}

.que-es {
  background-color: #f3f8fe;
  padding: 150px 0 100px;
}
@media (max-width: 991.98px) {
  .que-es {
    padding: 100px 0;
  }
}

#solucion svg {
  width: 100%;
  height: auto;
}

.nuestras-soluciones {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.nuestras-soluciones .pic {
  position: relative;
}
.nuestras-soluciones .pic img {
  position: relative;
  z-index: 10;
}
.nuestras-soluciones .pic .dots {
  position: absolute;
  top: 50%;
  left: -100px;
  transform: translateY(-50%);
}
@media (max-width: 767.98px) {
  .nuestras-soluciones .pic .dots {
    top: 50%;
    left: 50%;
    transform: translateY(0) translateX(-50%);
  }
}

.directorio {
  background-color: #f3f8fe;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.directorio .pic {
  position: relative;
}
.directorio .pic .fig {
  position: relative;
  z-index: 10;
}
.directorio .pic .dots {
  position: absolute;
  top: 0%;
  left: 50%;
}
@media (max-width: 767.98px) {
  .directorio .pic .fig {
    width: 150%;
    height: auto;
    margin-top: -100px;
  }
  .directorio .pic .dots {
    top: 80%;
    left: 50%;
    transform: translateY(0) translateX(-50%);
  }
}

.oportunidades {
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.oportunidades .pic {
  position: relative;
}
.oportunidades .pic .fig {
  position: relative;
  z-index: 10;
}
.oportunidades .pic .dots {
  position: absolute;
  top: 0%;
  right: 50%;
}
@media (max-width: 767.98px) {
  .oportunidades .pic .dots {
    top: 80%;
    right: 50%;
    transform: translateY(0) translateX(50%);
  }
}

.intercambio {
  background-color: #f3f8fe;
  padding: 100px 0 250px;
  position: relative;
  overflow: hidden;
}
.intercambio .pic {
  position: relative;
}
.intercambio .pic img {
  position: relative;
  z-index: 10;
}
.intercambio .pic .dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
}
@media (max-width: 991.98px) {
  .intercambio .pic .dots {
    top: 50%;
    left: 50%;
    transform: translateY(0) translateX(-50%);
  }
}

.infomedia {
  padding: 100px 0;
  padding-bottom: 250px;
}
.infomedia .pic {
  position: relative;
}
.infomedia .pic .fig {
  position: relative;
  z-index: 10;
}
.infomedia .pic .dots {
  position: absolute;
  top: 0%;
  right: -100px;
}

.cta-testimonios {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: auto;
  z-index: 20;
}
.cta-testimonios .block {
  position: absolute;
  width: calc(100% - 30px);
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background: #ffffff;
  border: 1px solid #add2ff;
  box-shadow: 4px 10px 20px rgba(160, 164, 167, 0.27);
  border-radius: 10px 10px 0px 0px;
}
.cta-testimonios .block .inner {
  padding: 50px;
}
.cta-testimonios .block .inner small {
  display: block;
  font-family: "DM Bold", Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: 5px;
  color: #ff4438;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.cta-testimonios .block .inner .lead {
  font-family: "Neue Regular", Helvetica, Arial, sans-serif;
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 1.45em;
}
.cta-testimonios .block .inner .lead span {
  color: #ff4438;
  font-size: 50px;
}
@media (max-width: 991.98px) {
  .cta-testimonios .block .inner .lead {
    font-size: 24px;
  }
}
@media (max-width: 767.98px) {
  .cta-testimonios .block .inner {
    padding: 50px 15px;
  }
  .cta-testimonios .block .inner .lead {
    font-size: 18px;
  }
  .cta-testimonios .block .inner .lead span {
    font-size: 22px;
  }
}

.proveedor-medios {
  background-color: #252a36;
  position: relative;
  overflow: hidden;
  padding: 250px 0 150px;
}
.proveedor-medios .contw {
  position: relative;
  z-index: 10;
}
.proveedor-medios .poster {
  position: absolute;
  top: 50px;
  right: 0;
  width: 65%;
  height: 100%;
}
.proveedor-medios .poster::before, .proveedor-medios .poster::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
}
.proveedor-medios .poster::before {
  width: 100%;
  height: 80%;
  background: linear-gradient(0deg, rgba(37, 42, 54, 0), #252a36);
}
.proveedor-medios .poster::after {
  width: 60%;
  height: 100%;
  background: linear-gradient(90deg, #252a36, rgba(37, 42, 54, 0));
}
.proveedor-medios .poster img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}
@media (max-width: 767.98px) {
  .proveedor-medios {
    padding-bottom: 100px;
  }
  .proveedor-medios .poster {
    width: 100%;
  }
  .proveedor-medios .poster::before {
    height: 100%;
  }
  .proveedor-medios .poster::after {
    width: 100%;
  }
  .proveedor-medios .poster img {
    object-position: right center;
  }
}
/*# sourceMappingURL=index.b1b4df04.css.map */
